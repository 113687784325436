import React from 'react';
import moment from 'moment';
import Slider from "react-slick";
import 'moment/locale/es'
import ContentImage from '../common/ContentImage.jsx';

const HomeAgendaComponent = (props) => {
    const homeSchedule = props?.schedule?.data;
    

    // useEffect(() => {
    //     _fetchHomeMagzine();
    // }, []);

    // const _fetchHomeMagzine = async () => {
    //     let schedule = await getHomeSchedule(1);
    //     if(schedule.responseCode == '201') {
    //         sethomeSchedule(schedule?.data);
    //     }
    // }
    const slider_setting_homeschedule = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToScroll: 1,
      slidesPerRow: 4,
      rows: 3,      
      responsive: [                
        {
          breakpoint: 1440,
          settings: {
            slidesPerRow: 3,
            rows: 3
          }
        }
        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
      ]
    };
    return (
        <div className="col-sm-8">
            <div className="box--wrap">
                <h6 className="novedades">EVENTOS</h6>
                <h2 className="A-la-venta-esta-sema">Agenda</h2>
                <div className="line5"></div>
                <div className="p-0 my-0 homepage_events_sec">
                    <section className="regular image_slider_homeevents">
                        <Slider {...slider_setting_homeschedule}>
                            {homeSchedule?.map( (val, i) => (
                                <div key={`schedule${i}`}>
                                    <div className="agenda" onClick={() => props?.handleModal(val)}>                                
                                        <div className="connoticias1">
                                            <div className="res">
                                                {/*<Image key={i} src={process.env.NEXT_PUBLIC_IMAGE_PATH+`${val.image}`} alt="" layout="fill" loading="eager"></Image>
                                                <img src={process.env.NEXT_PUBLIC_IMAGE_PATH+`${val.image}`} alt=""></img>*/}
                                                <ContentImage imageName={val.image} isImageComponent={false}/>
                                                <div className="date">
                                                    <h5>{moment(val.eventDate).locale('es').format('MMM').replace('.','')}</h5>
                                                    <p>{moment(val.eventDate,'YYYY-MM-DD').format('DD')}</p>
                                                </div>
                                            </div>
                                            <div>
                                                <h5 className="event-title" dangerouslySetInnerHTML={{__html:val.previewTitle}}></h5>
                                                <div className="event-text" dangerouslySetInnerHTML={{ __html:val.previewText}} ></div>                                                
                                            </div>
                                        </div>                                
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </section>
                </div>
            </div>
        </div>
    )
}

export default HomeAgendaComponent;
